<script setup lang="ts">
import { useScroll } from '@vueuse/core'
import { useStoriesStore } from '~/store/modal/stories'
import type { StoryBanner } from '~/type/Story'

interface Props {
  list: StoryBanner[]
}
defineProps<Props>()

const { $emitter } = useNuxtApp()
const { enable } = useStoriesStore()
const storyElement = shallowRef<HTMLDivElement>()
const { arrivedState } = useScroll(storyElement)

function onClick(banner: StoryBanner, index: number) {
  enable(banner.storyId)
  $emitter.emit('click-story-banner', index)
}
</script>

<template>
  <div class="story-banner">
    <div v-show="!arrivedState.left" class="story-banner__blur story-banner__blur_left" />
    <div
      ref="storyElement"
      class="story-banner__wrap"
    >
      <template v-for="storyBanner, index in list" :key="index">
        <div
          class="story-banner__item story-banner__item_desktop"
          :style="{ backgroundImage: `url(${storyBanner.banner.desktop})` }"
          @click="onClick(storyBanner, index)"
        >
          <div class="story-banner__border" />
        </div>
        <div
          class="story-banner__item story-banner__item_mobile"
          :style="{ backgroundImage: `url(${storyBanner.banner.mobile})` }"
          @click="onClick(storyBanner, index)"
        >
          <div class="story-banner__border" />
        </div>
      </template>
    </div>
    <div v-show="!arrivedState.right" class="story-banner__blur story-banner__blur_right" />
  </div>
</template>

<style lang="postcss" scoped>
.story-banner {

  &__border {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: 2px solid #f4f4f4;
    border-radius: 18px;
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
  }

  &__item {
    position: relative;
    padding-bottom: 139%;
    overflow: hidden;
    color: #fff;
    cursor: pointer;
    image-rendering: -webkit-optimize-contrast;
    background-repeat: no-repeat;
    background-position: inherit;
    background-size: cover;
    border-radius: 20px;

    &_mobile {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    position: relative;

    &__wrap {
      position: relative;
      grid-gap: 10px;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      padding-bottom: 120%;

      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
      }
    }
  }

  @media (max-width: 600px) {

    &__item {
      min-width: 135px;
      padding-bottom: 142%;
    }
  }

  @media (max-width: 450px) {

    &__blur {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 72px;
      height: 100%;
      content: "";

      &_right {
        right: -5px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      }

      &_left {
        left: 0;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
      }
    }
  }

  @media (max-width: 375px) {

    &__item {
      min-width: 112px;
    }
  }
}
</style>
