<script setup lang="ts">
import { useSocialsStore } from '~/store/socials'

const social = useSocialsStore()
</script>

<template>
  <div class="app-advertising">
    <h3 class="app-advertising__title">
      Лень выходить за продуктами?
    </h3>
    <p class="app-advertising__desc">
      скачай приложение, <br class="app-advertising__desc-br">привезем продукты за 30 мин.
    </p>
    <div class="app-advertising__links">
      <a
        v-if="social.appStoreUrl"
        :href="social.appStoreUrl"
        target="_blank"
        class="app-advertising__link app-advertising__link_apple"
      >
        <img
          class="app-advertising__link-img"
          src="./asset/apple.svg"
          alt="Apple"
        >
      </a>
      <a
        v-if="social.googlePlay"
        :href="social.googlePlay"
        target="_blank"
        class="app-advertising__link app-advertising__link_android"
      >
        <img
          class="app-advertising__link-img"
          src="./asset/android.svg"
          alt="Android"
        >
      </a>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.app-advertising {
  padding: 22px 32px 32px;
  background: url('./asset/app-delivery.png') no-repeat 116% 20%;
  background-color: #F8F8F8;
  background-size: 507px 564px;
  border-radius: 20px;
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background: url('./asset/app-delivery@2x.png') no-repeat 116% 20%;
    background-color: #F8F8F8;
    background-size: 507px 564px;
  }

  &__links {
    display: flex;
  }

  &__link {

    &_apple {
      width: 180px;
      height: 60px;
      padding: 11px 15px;
      margin-right: 10px;
      background-color: #000;
      border: 1px solid #A6A6A6;
      border-radius: 10px;
    }

    &_android {
      width: 203px;
      height: 60px;
      padding: 11px 15px;
      background-color: #000;
      border: 1px solid #A6A6A6;
      border-radius: 8px;
    }
  }

  &__title {
    max-width: 302px;
    margin-bottom: 28px;
    font-size: 36px;
    font-weight: 500;
    line-height: 120%;
  }

  &__desc {
    max-width: 262px;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 120%;

    &-br {
      display: none;
    }
  }

  @media (max-width: 1450px) {
    background: url('./asset/app-delivery.png') no-repeat 116% 13%;
    background-color: #F8F8F8;
    background-size: 370px 420px;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background: url('./asset/app-delivery@2x.png') no-repeat 116% 13%;
      background-color: #F8F8F8;
      background-size: 370px 420px;
    }

    &__title {
      margin-bottom: 48px;
    }

    &__link {

      &_apple {
        width: 120px;
        height: 40px;
        padding: 8px 10px;
        border-radius: 8px;

        ^&-img {
          width: 100px;
          height: 25px;
        }
      }

      &_android {
        width: 135px;
        height: 40px;
        padding: 8px 10px;
        border-radius: 6px;

        ^&-img {
          width: 116px;
          height: 27px;
        }
      }
    }
  }

  @media (max-width: 1310px) {
    background: url('./asset/app-delivery.png') no-repeat 116% -25%;
    background-color: #F8F8F8;
    background-size: 320px 370px;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background: url('./asset/app-delivery@2x.png') no-repeat 116% -25%;
      background-color: #F8F8F8;
      background-size: 320px 370px;
    }
  }

  @media (max-width: 1140px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    background-color: #F8F8F8;

    &__title {
      width: 275px;
    }
  }

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 415px;
    padding: 20px 0;
    margin-right: -8px;
    text-align: center;
    background: url('./asset/app-delivery-mobile.png') no-repeat calc(50% - 2px) 317%;
    background-color: #F8F8F8;
    background-size: 300px 347px;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background: url('./asset/app-delivery-mobile@2x.png') no-repeat calc(50% - 2px) 317%;
      background-color: #F8F8F8;
      background-size: 300px 347px;
    }

    &__title {
      max-width: 183px;
      margin-bottom: 13px;
      font-size: 24px;
    }

    &__desc {
      margin-bottom: 20px;
      font-size: 16px;

      &-br {
        display: block;
      }
    }

    &__links {
      flex-direction: column;
      align-items: center;
    }

    &__link {

      &_apple {
        width: 130px;
        height: 42px;
        margin: 0 0 11px 0;

        ^&-img {
          width: 110px;
          height: 27px;
        }
      }

      &_android {
        width: 149px;
        height: 44px;

        ^&-img {
          width: 127px;
          height: 30px;
        }
      }
    }
  }
}
</style>
