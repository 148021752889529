<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useUserStore } from '~/store/user'

const activeBlockList = [
  {
    title: 'Вы покупали',
    link: 'previously-order/',
    key: 'previously-order',
  },
  {
    title: 'Новинки',
    link: 'new-product/',
    key: 'new-product',
  },
  {
    title: 'Скидки',
    link: 'sale/',
    key: 'sale',
  },
  {
    title: 'Избранное',
    link: 'favorite/',
    key: 'favorite',
  },
]

const { isGuest } = storeToRefs(useUserStore())
const isActiveClick = ref(false)

const currentList = computed(() => isGuest.value ? activeBlockList.slice(1) : activeBlockList)

function onLinkClick(link: string) {
  return isActiveClick.value ? '' : link
}
</script>

<template>
  <div class="active-block">
    <nuxt-link
      v-for="(el, i) in currentList"
      :key="i"
      :data-e2e="el.title"
      class="active-block__item"
      :to="onLinkClick(el.link)"
      :class="[`active-block__item_${el.key}`, { 'active-block__item_sales': isGuest && i === 0 }]"
      @click="isActiveClick = true"
    >
      <p>{{ el.title }}</p>
    </nuxt-link>
  </div>
</template>

<style lang="postcss" scoped>
.active-block {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 40px 19px;
    color: black;
    text-decoration: none;
    text-wrap: nowrap;
    cursor: pointer;
    background-color: #F8F8F8;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 60px;
    border-radius: 22px;

    &_sales {
      flex-direction: column;
      grid-row: 1 / span 2;
      align-items: start;
      justify-content: space-around;
    }

    &_previously-order {
      background-image: url('./asset/sync.svg');
    }

    &_favorite {
      background-image: url('./asset/like.svg');
    }

    &_sale {
      background-image: url('./asset/percent.svg');
    }

    &_new-product {
      background-image: url('./asset/new.svg');
    }
  }

  @media (max-width: 1000px) {
    display: flex;
    flex-wrap: nowrap;
    gap: 0;

    &__item {
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      height: 63px;
      padding: 0;
      background-color: #fff;
      background-position: 50% 0;
      background-size: 44px;
      border-radius: 0;

      &_sales {
        align-items: center;
        justify-content: flex-end;
      }

      & > p {
        font-size: 12px;
        line-height: 14px;
      }

      &_previously-order {
        background-image: url('./asset/sync-mobile.svg');
      }

      &_favorite {
        background-image: url('./asset/like-mobile.svg');
      }

      &_sale {
        background-image: url('./asset/percent-mobile.svg');
      }

      &_new-product {
        background-image: url('./asset/new-mobile.svg');
      }
    }
  }
}
</style>
